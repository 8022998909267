import React, { useState } from 'react';
import { AlertCircle } from 'lucide-react';
import { Alert, AlertTitle, AlertDescription } from './Alert';

const NDAModal = ({ isOpen, onAccept }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [error, setError] = useState('');

  if (!isOpen) return null;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isChecked) {
      setError('You must acknowledge the agreement to continue');
      return;
    }
    onAccept();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-3xl mx-4 max-h-[90vh] overflow-y-auto">
        <h2 className="text-2xl font-bold mb-4">Non-Disclosure Agreement</h2>
        
        {error && (
          <Alert className="mb-4 bg-red-50">
            <AlertCircle className="h-4 w-4" />
            <AlertTitle>Error</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}

        <div className="prose prose-sm max-w-none mb-6">
          <h3 className="text-lg font-semibold">1. Confidential Information</h3>
          <p>
            The undersigned acknowledges that during the course of using Vector Intelligence's services, 
            they may have access to and become acquainted with confidential and proprietary information 
            ("Confidential Information") including but not limited to trade secrets, technical data, 
            product specifications, customer lists, business plans, and other proprietary information.
          </p>

          <h3 className="text-lg font-semibold mt-4">2. Non-Disclosure</h3>
          <p>
            The undersigned agrees not to disclose any Confidential Information, directly or indirectly, 
            or use such information in any way, either during or after the term of this agreement, 
            except as required in the course of their engagement with Vector Intelligence.
          </p>

          <h3 className="text-lg font-semibold mt-4">3. Return of Materials</h3>
          <p>
            Upon termination of access or upon request, the undersigned will promptly return to 
            Vector Intelligence all documents, records, and materials containing any Confidential Information.
          </p>

          <h3 className="text-lg font-semibold mt-4">4. Duration</h3>
          <p>
            The obligations contained in this Agreement will continue indefinitely or until the 
            Confidential Information disclosed to the undersigned is no longer confidential.
          </p>

          <h3 className="text-lg font-semibold mt-4">5. Breach</h3>
          <p>
            The undersigned understands and acknowledges that any disclosure or misappropriation of 
            any Confidential Information in violation of this Agreement may cause Vector Intelligence 
            irreparable harm, and therefore agrees that Vector Intelligence shall have the right to 
            seek immediate injunctive relief.
          </p>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="flex items-start space-x-2">
            <input
              type="checkbox"
              id="agreement"
              checked={isChecked}
              onChange={(e) => {
                setIsChecked(e.target.checked);
                if (e.target.checked) setError('');
              }}
              className="mt-1"
            />
            <label htmlFor="agreement" className="text-sm">
              I acknowledge that I have read, understand, and agree to be bound by all the terms and 
              conditions of this Non-Disclosure Agreement.
            </label>
          </div>

          <div className="flex justify-end">
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 disabled:opacity-50"
              disabled={!isChecked}
            >
              Accept & Continue
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NDAModal;