// steelGradeConstants.js

export const STEEL_GRADE_INFO = {
    W_SHAPES: {
      options: ['A992', 'A572 Gr.50', 'A36'],
      descriptions: {
        'A992': 'High-strength low-alloy steel, commonly used for wide flange sections. Minimum yield strength of 50 ksi.',
        'A572 Gr.50': 'High-strength low-alloy structural steel with 50 ksi minimum yield strength.',
        'A36': 'Standard structural steel with 36 ksi minimum yield strength.'
      }
    },
    T_SHAPES: {
      options: ['A572 Gr.50', 'A36'],
      descriptions: {
        'A572 Gr.50': 'High-strength low-alloy structural steel with 50 ksi minimum yield strength.',
        'A36': 'Standard structural steel with 36 ksi minimum yield strength.'
      }
    },
    L_SHAPES: {
      options: ['A36', 'A572 Gr.50'],
      descriptions: {
        'A36': 'Standard structural steel with 36 ksi minimum yield strength.',
        'A572 Gr.50': 'High-strength low-alloy structural steel with 50 ksi minimum yield strength.'
      }
    },
    HSS: {
      options: ['A500 Gr.B', 'A500 Gr.C', 'A1085'],
      descriptions: {
        'A500 Gr.B': 'Cold-formed welded and seamless carbon steel structural tubing, Grade B.',
        'A500 Gr.C': 'Cold-formed welded and seamless carbon steel structural tubing, Grade C with higher strength.',
        'A1085': 'Higher strength and enhanced properties compared to A500, with tighter tolerances.'
      }
    },
    PIPE: {
      options: ['A53 Gr.B', 'A500 Gr.B', 'API 5L'],
      descriptions: {
        'A53 Gr.B': 'Standard pipe for mechanical and pressure applications.',
        'A500 Gr.B': 'Cold-formed structural tubing.',
        'API 5L': 'Line pipe for transportation of gas, water, and oil.'
      }
    },
    PLATE: {
      options: ['A36', 'A572 Gr.50', 'A588', 'A709'],
      descriptions: {
        'A36': 'Standard structural steel plate.',
        'A572 Gr.50': 'High-strength structural steel plate.',
        'A588': 'High-strength low-alloy structural steel with improved atmospheric corrosion resistance.',
        'A709': 'Structural steel plate for bridges.'
      }
    }
  };
  
  export const PAINT_FINISH_INFO = {
    options: ['Shop Primer', 'Hot-Dipped Galvanized', 'Powder Coat', 'Painted', 'None'],
    descriptions: {
      'Shop Primer': 'Basic protective coating applied in shop, typically requires field painting.',
      'Hot-Dipped Galvanized': 'Zinc coating applied through hot-dip process, excellent corrosion resistance.',
      'Powder Coat': 'Durable, decorative finish applied electrostatically.',
      'Painted': 'Full paint system applied in shop.',
      'None': 'No coating applied.'
    }
  };
  
  export const WELD_SPEC_INFO = {
    options: ['AWS D1.1', 'AWS D1.3', 'AWS D1.8'],
    descriptions: {
      'AWS D1.1': 'Structural Welding Code - Steel',
      'AWS D1.3': 'Structural Welding Code - Sheet Steel',
      'AWS D1.8': 'Structural Welding Code - Seismic Supplement'
    }
  };

  export const LOCATION_INFO = {
    options: [], // Will be replaced by redux state in components
    descriptions: {} // Will be populated in components
  };