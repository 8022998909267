import React, { useState, useEffect } from 'react';
import { AlertCircle, Plus, Edit2, Trash2 } from 'lucide-react';
import { Alert, AlertTitle, AlertDescription } from './Alert';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLocations, addLocation, updateLocation, deleteLocation } from '../slices/pdfSlice';

const AddLocationModal = ({ isOpen, onClose, onSubmit, editingLocation, initialValue = '' }) => {
    const [locationName, setLocationName] = useState(initialValue);
  
    useEffect(() => {
      setLocationName(initialValue);
    }, [initialValue]);
  
    const handleSubmit = (e) => {
      e.preventDefault();
      onSubmit(locationName);
    };
  
    if (!isOpen) return null;
  
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg p-6 w-full max-w-md mx-4">
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              value={locationName}
              onChange={(e) => setLocationName(e.target.value)}
              className="w-full p-2 border rounded"
            />
            <div className="flex justify-end gap-3 mt-4">
              <button type="button" onClick={onClose}>Cancel</button>
              <button type="submit">Save</button>
            </div>
          </form>
        </div>
      </div>
    );
  };

const LocationManager = () => {
    const [locationsList, setLocationsList] = useState([]); // Rename from locations
    const { items: locations, error: locationError, status } = useSelector(state => state.pdfs.locations);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingLocation, setEditingLocation] = useState(null);
    const [newLocationName, setNewLocationName] = useState('');
    const [error, setError] = useState(null);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchLocations());
    }, [dispatch]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (editingLocation) {
                await dispatch(updateLocation({ 
                    id: editingLocation.id, 
                    name: newLocationName 
                }));
            } else {
                await dispatch(addLocation({ name: newLocationName }));
            }
            setIsModalOpen(false);
            setNewLocationName('');
            setEditingLocation(null);
        } catch (error) {
            setError(error.message);
        }
    };

    const handleDelete = async (locationId) => {
        if (!window.confirm('Are you sure you want to delete this location?')) return;
        try {
            await dispatch(deleteLocation(locationId));
        } catch (error) {
            setError(error.message);
        }
    };

    

    return (
        <div className="p-6">
            <div className="flex justify-between items-center mb-6">
                <h2 className="text-2xl font-bold">Manage Locations</h2>
                <button
                    onClick={() => {
                        setEditingLocation(null);
                        setNewLocationName('');
                        setIsModalOpen(true);
                    }}
                    className="flex items-center px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                >
                    <Plus className="w-4 h-4 mr-2" />
                    Add Location
                </button>
            </div>

            {error && (
                <Alert className="mb-4">
                    <AlertCircle className="h-4 w-4" />
                    <AlertTitle>Error</AlertTitle>
                    <AlertDescription>{error}</AlertDescription>
                </Alert>
            )}

            <div className="bg-white rounded-lg shadow">
                <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Location Name
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {locations.map(location => (
                                <tr key={location.id}>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        {location.name}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="flex space-x-2">
                                            <button
                                                onClick={() => {
                                                    setEditingLocation(location);
                                                    setNewLocationName(location.name);
                                                    setIsModalOpen(true);
                                                }}
                                                className="text-blue-600 hover:text-blue-800"
                                            >
                                                <Edit2 className="w-4 h-4" />
                                            </button>
                                            <button
                                                onClick={() => handleDelete(location.id)}
                                                className="text-red-600 hover:text-red-800"
                                            >
                                                <Trash2 className="w-4 h-4" />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            <AddLocationModal
    isOpen={isModalOpen}
    onClose={() => {
        setIsModalOpen(false);
        setEditingLocation(null);
        setNewLocationName('');
    }}
    onSubmit={async (locationName) => {
        try {
            if (editingLocation) {
                await dispatch(updateLocation({ id: editingLocation.id, name: locationName }));
            } else {
                await dispatch(addLocation(locationName));  // not { name: locationName }
            }
            setIsModalOpen(false);
            setEditingLocation(null);
        } catch (error) {
            setError(error.message);
        }
    }}
    editingLocation={editingLocation}
    initialValue={editingLocation ? editingLocation.name : ''}
/>
        </div>
    );
};

export default LocationManager;