import React from 'react';
import { Loader } from 'lucide-react';

const LoadingOverlay = ({ message = "Loading..." }) => (
  <div className="fixed inset-0 bg-black/50 flex items-center justify-center" style={{ zIndex: 9999 }}>
    <div className="bg-white p-4 rounded-lg shadow-lg space-y-3">
      <div className="flex items-center gap-3">
        <Loader className="h-6 w-6 animate-spin text-blue-600" />
        <span className="text-gray-700">{message}</span>
      </div>
      {message.includes('%') && (
        <div className="w-64 h-2 bg-gray-200 rounded-full">
          <div 
            className="h-full bg-blue-600 rounded-full transition-all duration-300"
            style={{ width: `${message.match(/\d+/)[0]}%` }}
          />
        </div>
      )}
    </div>
  </div>
);

export default LoadingOverlay;