import React from 'react';
import { useSelector } from 'react-redux';
import { selectUserParameters } from '../slices/authSlice';
import { STEEL_GRADE_INFO, PAINT_FINISH_INFO, WELD_SPEC_INFO, LOCATION_INFO } from './steelGradeConstants';

const ParameterDisplay = () => {
  const parameters = useSelector(selectUserParameters);

  if (!parameters || parameters.length === 0) {
    return (
      <div className="bg-white rounded-lg shadow-lg p-6">
        <p className="text-gray-500 text-center">No parameters set</p>
      </div>
    );
  }

  const getDescription = (name, value) => {
    if (name.includes('steel_grade')) {
      const type = name.split('_')[1].toUpperCase();
      return STEEL_GRADE_INFO[`${type}_SHAPES`]?.descriptions[value];
    } else if (name === 'default_paint_finish') {
      return PAINT_FINISH_INFO.descriptions[value];
    } else if (name === 'default_weld_specification') {
      return WELD_SPEC_INFO.descriptions[value];
    } else if (name === 'default_location') {
      return LOCATION_INFO.descriptions[value];
    }
    return null;
  };

  const sections = {
    'Steel Grades': parameters.filter(p => p.name.includes('steel_grade')),
    'General Settings': parameters.filter(p => !p.name.includes('steel_grade'))
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <h3 className="text-xl font-bold mb-6">Current Parameters</h3>
      {Object.entries(sections).map(([sectionName, params]) => (
        <div key={sectionName} className="mb-6">
          <h4 className="text-lg font-semibold mb-4">{sectionName}</h4>
          <div className="grid grid-cols-1 gap-4">
            {params.map(param => (
              <div key={param.name} className="border-b pb-4">
                <div className="font-medium text-gray-700">
                  {param.name.split('_').slice(1).map(word => 
                    word.charAt(0).toUpperCase() + word.slice(1)
                  ).join(' ')}
                </div>
                <div className="text-lg mt-1">{param.value}</div>
                {getDescription(param.name, param.value) && (
                  <div className="text-sm text-gray-500 mt-1">
                    {getDescription(param.name, param.value)}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ParameterDisplay;