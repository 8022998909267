import React, { useState, useEffect } from 'react';
import { AlertCircle } from 'lucide-react';

const FailureTest = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [userData, setUserData] = useState(null);

  // Fetch user data when we get a response
  useEffect(() => {
    const fetchUserData = async () => {
      if (response?.uploaded_by_id) {
        try {
          const userResponse = await fetch('/client/api/users', {
            credentials: 'include'
          });
          const users = await userResponse.json();
          console.log('All Users:', users);
          const uploader = users.find(u => u.id === response.uploaded_by_id);
          console.log('Found Uploader:', uploader);
          setUserData(uploader);
        } catch (err) {
          console.error('Error fetching user data:', err);
          setError(err.message);
        }
      }
    };

    fetchUserData();
  }, [response]);

  const testFailureNotification = async () => {
    setIsLoading(true);
    setError(null);
    setResponse(null);
    setUserData(null);
    
    try {
      const response = await fetch('/client/api/pdfs/13/status', {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          status: 'failed',
          failureReason: 'test_failure',
          notes: 'Test failure notification',
          notifyClient: true
        }),
        credentials: 'include'
      });

      const data = await response.json();
      console.log('Server Response:', data);
      setResponse(data);
    } catch (err) {
      console.error('Error:', err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="p-4 space-y-4">
      <button
        onClick={testFailureNotification}
        disabled={isLoading}
        className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 disabled:opacity-50"
      >
        {isLoading ? 'Testing...' : 'Test Failure Notification'}
      </button>

      {error && (
        <div className="p-4 bg-red-50 text-red-700 rounded flex items-center">
          <AlertCircle className="w-5 h-5 mr-2" />
          {error}
        </div>
      )}

      {response && (
        <>
          <div className="font-bold">PDF Response:</div>
          <pre className="p-4 bg-gray-100 rounded overflow-auto">
            {JSON.stringify(response, null, 2)}
          </pre>
        </>
      )}

      {userData && (
        <>
          <div className="font-bold">Uploader Information:</div>
          <pre className="p-4 bg-gray-100 rounded overflow-auto">
            {JSON.stringify(userData, null, 2)}
          </pre>
        </>
      )}
    </div>
  );
};

export default FailureTest;