import React, { useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { uploadPDF, fetchPDFs, deletePDF, selectAllPDFs } from '../slices/pdfSlice';
import { Eye, Download, AlertCircle, CheckCircle, Clock, AlertTriangle, Loader, Trash2 } from 'lucide-react';
import LoadingOverlay from './LoadingOverlay';


const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      onClick={onClose}
    >
      <div
        className="bg-white rounded-lg p-6 w-[500px] max-h-[90vh] overflow-y-auto"
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  );
};

function PDFList() {
  const dispatch = useDispatch();
  // const pdfs = useSelector(state => state.pdfs.documents);
  const pdfs = useSelector(selectAllPDFs);
  const [uploadProgress, setUploadProgress] = useState(0);
  const user = useSelector(state => state.auth.user);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    key: 'filename',
    direction: 'asc'
  });
  const [isUploading, setIsUploading] = useState(false);
  const [isPreviewing, setIsPreviewing] = useState(false);
  const CHUNK_SIZE = 5 * 1024 * 1024;
  const retries = 3;
  const MAX_CONCURRENT = 3;


// const uploadChunk = async (chunk, index, file, metadata, fileId) => {
//   const chunkForm = new FormData();
//   const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
  
//   chunkForm.append('chunk', chunk);
//   chunkForm.append('index', index);
//   chunkForm.append('totalChunks', totalChunks);
//   chunkForm.append('fileId', fileId);
  
//   // Send metadata with first chunk
//   if (index === 0 && metadata) {
//     Object.entries(metadata).forEach(([key, value]) => {
//       chunkForm.append(key, value);
//     });
//     // Also save metadata to a file
//     const metaBlob = new Blob([JSON.stringify(metadata)], { type: 'application/json' });
//     chunkForm.append('metadata', metaBlob, 'meta_data.json');
//   }

//   const response = await fetch('/client/api/upload-pdf', {
//     method: 'POST',
//     body: chunkForm,
//     credentials: 'include'
//   });

//   if (!response.ok) throw new Error(`Chunk ${index + 1} failed`);
//   return response.json();
// };


const uploadChunk = async (chunk, index, file, metadata) => {
  const retries = 3;
  let lastError;
  
  for (let attempt = 0; attempt < retries; attempt++) {
    try {
      const chunkForm = new FormData();
      chunkForm.append('chunk', chunk);
      chunkForm.append('index', index);
      chunkForm.append('totalChunks', Math.ceil(file.size / CHUNK_SIZE));
      chunkForm.append('fileId', file.id); // Use consistent fileId for all chunks
      
      // Add metadata to final chunk
      if (index === Math.ceil(file.size / CHUNK_SIZE) - 1) {
        Object.keys(metadata).forEach(key => {
          chunkForm.append(key, metadata[key]);
        });
      }

      const response = await fetch('/client/api/upload-pdf', {
        method: 'POST',
        body: chunkForm,
        credentials: 'include'
      });

      if (!response.ok) throw new Error(`Chunk ${index + 1} failed`);
      return response.json();
      
    } catch (err) {
      lastError = err;
      await new Promise(resolve => setTimeout(resolve, 1000 * attempt));
      continue;
    }
  }
  throw lastError;
};
// const handleUpload = async (file, metadata) => {
//   const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
  
//   for (let i = 0; i < totalChunks; i += MAX_CONCURRENT) {
//     const chunkPromises = [];
//     const end = Math.min(i + MAX_CONCURRENT, totalChunks);
    
//     for (let j = i; j < end; j++) {
//       const start = j * CHUNK_SIZE;
//       const chunk = file.slice(start, Math.min(start + CHUNK_SIZE, file.size));
//       chunkPromises.push(uploadChunk(chunk, j, file, metadata));
//     }
    
//     await Promise.all(chunkPromises);
//   }
// };

const handleUpload = async (file, metadata) => {
  file.id = `${Date.now()}_${file.name}`;
  const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
  let uploadedChunks = 0;
  
  for (let i = 0; i < totalChunks; i += MAX_CONCURRENT) {
    const chunkPromises = [];
    const end = Math.min(i + MAX_CONCURRENT, totalChunks);
    
    for (let j = i; j < end; j++) {
      const start = j * CHUNK_SIZE;
      const chunk = file.slice(start, Math.min(start + CHUNK_SIZE, file.size));
      chunkPromises.push(
        uploadChunk(chunk, j, file, metadata)
          .then(() => {
            uploadedChunks++;
            const progress = Math.round((uploadedChunks / totalChunks) * 100);
            setUploadProgress(progress);
          })
      );
    }
    await Promise.all(chunkPromises);
  }
};

  // const uploadChunk = async (chunk, index, totalChunks, fileId) => {
  //   const formData = new FormData();
  //   formData.append('chunk', chunk);
  //   formData.append('index', index);
  //   formData.append('totalChunks', totalChunks);
  //   formData.append('fileId', fileId);

  //   const response = await fetch('/client/api/upload-pdf', {
  //     method: 'POST',
  //     body: formData,
  //     credentials: 'include',
  //   });

  //   if (!response.ok) {
  //     throw new Error(`Failed to upload chunk ${index + 1} of ${totalChunks}`);
  //   }
  // };

  const handleChunkedUpload = async () => {
    if (!selectedFile) return;
  
    setIsUploading(true);
    try {
      const totalChunks = Math.ceil(selectedFile.size / CHUNK_SIZE);
      const fileId = `${Date.now()}_${selectedFile.name}`;
  
      const chunkPromises = [];
      for (let i = 0; i < totalChunks; i++) {
        const start = i * CHUNK_SIZE;
        const end = Math.min(selectedFile.size, start + CHUNK_SIZE);
        const chunk = selectedFile.slice(start, end);
  
        const formData = new FormData();
        formData.append('chunk', chunk);
        formData.append('index', i);
        formData.append('totalChunks', totalChunks);
        formData.append('fileId', fileId);
  
        chunkPromises.push(
          fetch('/client/api/upload-pdf', {
            method: 'POST',
            body: formData,
            credentials: 'include',
          })
        );
      }
    
      await Promise.all(chunkPromises); // Upload all chunks concurrently
      alert('File uploaded successfully!');
      dispatch(fetchPDFs());
    } catch (error) {
      console.error('Chunked upload failed:', error);
      alert('Failed to upload file. Please try again.');
    } finally {
      setIsUploading(false);
    }
  };
  
  const getViewModeText = () => {
    switch(user.role) {
      case 'parent_admin':
        return "Viewing all documents across organizations";
      case 'admin':
        return "Viewing all organization documents";
      default:
        return "Viewing your uploaded documents";
    }
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case 'pending':
        return <AlertCircle className="w-4 h-4 mr-1" />;
      case 'completed':
        return <CheckCircle className="w-4 h-4 mr-1" />;
      case 'processing':
        return <Loader className="w-4 h-4 mr-1 animate-spin" />;
      case 'failed':
        return <AlertTriangle className="w-4 h-4 mr-1" />;
      default:
        return <Clock className="w-4 h-4 mr-1" />;
    }
  };
  
  const getStatusStyles = (status) => {
    switch (status) {
      case 'pending':
        return 'bg-purple-100 text-purple-800';
      case 'completed':
        return 'bg-green-100 text-green-800';
      case 'processing':
        return 'bg-blue-100 text-blue-800';
      case 'failed':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-yellow-100 text-yellow-800';
    }
  };
  
  // const RelatedFiles = ({ pdf, onPreview }) => {
  //   // Only show processed/CSV/XML buttons if PDF is processed
  //   const handleDownload = async (type) => {
  //     try {
  //       const response = await fetch(`/client/api/pdfs/${pdf.id}/files/${type}`, {
  //         credentials: 'include'
  //       });
  //       const blob = await response.blob();
  //       const url = window.URL.createObjectURL(blob);
  //       const a = document.createElement('a');
  //       a.href = url;
  //       a.download = `${type}_file.${type}`;
  //       document.body.appendChild(a);
  //       a.click();
  //       document.body.removeChild(a);
  //       window.URL.revokeObjectURL(url);
  //     } catch (error) {
  //       console.error(`Error downloading ${type} file:`, error);
  //     }
  //   };
  
  //   return (
  //     <div className="flex space-x-2">
  //       {/* Original PDF button always shows */}
  //       <button
  //         onClick={() => onPreview(pdf.id, 'original')}
  //         className="text-xs bg-indigo-50 text-indigo-600 px-2 py-1 rounded hover:bg-indigo-100"
  //       >
  //         <Eye className="w-3 h-3 inline mr-1" />
  //         Original
  //       </button>
  
  //       {/* Show these buttons only if processed */}
  //       {(pdf.processed || pdf.status === 'completed') && (
  //         <>
  //           <button
  //             onClick={() => onPreview(pdf.id, 'processed')}
  //             className="text-xs bg-green-50 text-green-600 px-2 py-1 rounded hover:bg-green-100"
  //           >
  //             <Eye className="w-3 h-3 inline mr-1" />
  //             Processed
  //           </button>
            
  //           <button
  //             onClick={() => handleDownload('csv')}
  //             className="text-xs bg-blue-50 text-blue-600 px-2 py-1 rounded hover:bg-blue-100"
  //           >
  //             <Download className="w-3 h-3 inline mr-1" />
  //             CSV
  //           </button>
            
  //           <button
  //             onClick={() => handleDownload('xml')}
  //             className="text-xs bg-yellow-50 text-yellow-600 px-2 py-1 rounded hover:bg-yellow-100"
  //           >
  //             <Download className="w-3 h-3 inline mr-1" />
  //             XML
  //           </button>
  //         </>
  //       )}
  //     </div>
  //   );
  // };

  const RelatedFiles = ({ pdf, onPreview }) => {
    const handleDownload = async (type) => {
      try {
        const response = await fetch(`/client/api/pdfs/${pdf.id}/files/${type}`, {
          credentials: 'include'  
        });
        if (!response.ok) throw new Error(`Failed to download ${type}`);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${pdf.filename.split('.')[0]}.${type}`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error(`Error downloading ${type} file:`, error);
        alert(`Failed to download ${type} file`);
      }
    };
  
    return (
      <div className="flex space-x-2">
        <button onClick={() => onPreview(pdf.id, 'original')} 
          className="text-xs bg-indigo-50 text-indigo-600 px-2 py-1 rounded hover:bg-indigo-100">
          <Eye className="w-3 h-3 inline mr-1" />
          Original
        </button>
  
        {pdf.status === 'completed' && (
          <>
            <button onClick={() => onPreview(pdf.id, 'processed')}
              className="text-xs bg-green-50 text-green-600 px-2 py-1 rounded hover:bg-green-100">
              <Eye className="w-3 h-3 inline mr-1" />
              Processed
            </button>
            
            <button onClick={() => handleDownload('csv')}
              className="text-xs bg-blue-50 text-blue-600 px-2 py-1 rounded hover:bg-blue-100">
              <Download className="w-3 h-3 inline mr-1" />
              CSV
            </button>
            
            <button onClick={() => handleDownload('xml')}
              className="text-xs bg-yellow-50 text-yellow-600 px-2 py-1 rounded hover:bg-yellow-100">
              <Download className="w-3 h-3 inline mr-1" />
              XML
            </button>
          </>
        )}
      </div>
    );
  };

  const [formData, setFormData] = useState({
    expected_order_date: '',
    project_location: '',
    quote_needed_by: '',
    is_domestic_steel: null,
    contractor_location: '',
    status: 'pending',
  });

  const handleSort = (key) => {
    setSortConfig({
      key,
      direction: sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc'
    });
  };

  const sortedPDFs = [...pdfs].sort((a, b) => {
    let aVal = a[sortConfig.key];
    let bVal = b[sortConfig.key];

    if (sortConfig.key === 'status') {
      aVal = a.status || 'pending';
      bVal = b.status || 'pending';
    }
    else if (sortConfig.key === 'organization') {
      aVal = a.organization?.name || '';
      bVal = b.organization?.name || '';
    } else if (sortConfig.key.startsWith('questionnaire.')) {
      const nestedKey = sortConfig.key.split('.')[1];
      aVal = a.questionnaire?.[nestedKey] || '';
      bVal = b.questionnaire?.[nestedKey] || '';
    }

    if (aVal < bVal) return sortConfig.direction === 'asc' ? -1 : 1;
    if (aVal > bVal) return sortConfig.direction === 'asc' ? 1 : -1;
    return 0;
  });

  const filteredPDFs = sortedPDFs.filter(pdf =>
    pdf.filename.toLowerCase().includes(searchQuery.toLowerCase()) ||
    (pdf.organization?.name || '').toLowerCase().includes(searchQuery.toLowerCase())
  );

  const SortIndicator = ({ columnKey }) => {
    if (sortConfig.key !== columnKey) return null;
    return (
      <span className="ml-1">
        {sortConfig.direction === 'asc' ? '↑' : '↓'}
      </span>
    );
  };

  // const handleFileSelect = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     setSelectedFile(file);
  //     setIsModalOpen(true);
  //     // Reset the input value so the same file can be selected again
  //     event.target.value = '';
  //   }
  // };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedFile(null);
    setFormData({
      expected_order_date: '',
      project_location: '',
      quote_needed_by: '',
      contractor_location: '',
      is_domestic_steel: null
    });
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   if (!selectedFile) return;
  
  //   setIsUploading(true);
  //   const submitData = new FormData();
  //   submitData.append('file', selectedFile);
    
  //   // Format dates properly
  //   const expected_order_date = new Date(formData.expected_order_date).toISOString().split('T')[0];
  //   const quote_needed_by = new Date(formData.quote_needed_by).toISOString().split('T')[0];
    
  //   // Add form data with proper formatting
  //   submitData.append('expected_order_date', expected_order_date);
  //   submitData.append('project_location', formData.project_location);
  //   submitData.append('quote_needed_by', quote_needed_by);
  //   submitData.append('contractor_location', formData.contractor_location);
  //   submitData.append('is_domestic_steel', formData.is_domestic_steel === true ? 'true' : 'false');
  //   submitData.append('status', 'pending');
  
  //   try {
  //     await dispatch(uploadPDF(submitData)).unwrap();
  //     await dispatch(fetchPDFs());
  //     handleModalClose();
  //   } catch (error) {
  //     console.error('Upload error:', error);
  //     alert(error.message || 'Failed to upload PDF. Please try again.');
  //   } finally {
  //     setIsUploading(false);
  //   }
  // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   if (!selectedFile) return;
  
  //   setIsUploading(true);
  //   const metadata = {
  //     expected_order_date: new Date(formData.expected_order_date).toISOString().split('T')[0],
  //     project_location: formData.project_location,
  //     quote_needed_by: new Date(formData.quote_needed_by).toISOString().split('T')[0],
  //     contractor_location: formData.contractor_location,  
  //     is_domestic_steel: formData.is_domestic_steel ? 'true' : 'false'
  //   };
  
  //   try {
  //     await handleUpload(selectedFile, metadata);
  //     dispatch(fetchPDFs());
  //     handleModalClose();
  //   } catch (error) {
  //     console.error('Upload failed:', error);
  //     alert('Failed to upload file. Please try again.');
  //   } finally {
  //     setIsUploading(false);
  //   }
  // };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setIsModalOpen(true);
      event.target.value = '';
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedFile) return;

    setIsUploading(true);
    const submitData = new FormData();
    submitData.append('file', selectedFile);
    
    const expected_order_date = new Date(formData.expected_order_date).toISOString().split('T')[0];
    const quote_needed_by = new Date(formData.quote_needed_by).toISOString().split('T')[0];
    
    submitData.append('expected_order_date', expected_order_date);
    submitData.append('project_location', formData.project_location);
    submitData.append('quote_needed_by', quote_needed_by);
    submitData.append('contractor_location', formData.contractor_location);
    submitData.append('is_domestic_steel', formData.is_domestic_steel === true ? 'true' : 'false');
    submitData.append('status', 'new');

    try {
      await dispatch(uploadPDF(submitData)).unwrap();
      await dispatch(fetchPDFs());
      handleModalClose();
    } catch (error) {
      console.error('Upload error:', error);
      alert(error.message || 'Failed to upload PDF');
    } finally {
      setIsUploading(false);
    }
  };
  
  //     await dispatch(fetchPDFs());
  //     handleModalClose();
  //     alert('File uploaded successfully!');
  //   } catch (error) {
  //     console.error('Chunked upload failed:', error);
  //     alert('Failed to upload file. Please try again.');
  //   } finally {
  //     setIsUploading(false);
  //   }
  // };
  const handleDelete = (pdfId) => {
    if (window.confirm('Are you sure you want to delete this document?')) {
      dispatch(deletePDF(pdfId));
    }
  };


  // const handlePreview = async (pdfId, version = 'original') => {
  //   try {
  //     setIsPreviewing(true);
  //     console.log('Loading preview...');
      
  //     const response = await fetch(`/client/api/pdfs/${pdfId}/preview?version=${version}`, {
  //       credentials: 'include'
  //     });

  //     if (!response.ok) {
  //       throw new Error('Failed to load preview');
  //     }

  //     const blob = await response.blob();
  //     const url = window.URL.createObjectURL(blob);

  //     // Create a new window/tab for preview
  //     window.open(url, '_blank', 'noopener,noreferrer');

  //     // Clean up the URL object after the window is opened
  //     setTimeout(() => {
  //       window.URL.revokeObjectURL(url);
  //     }, 100);

  //   } catch (error) {
  //     console.error('Preview failed:', error);
  //     alert('Failed to load preview. Please try again.');
  //   } finally {
  //     setIsPreviewing(false);
  //     console.log('Preview loading complete');
  //   }
  // };

  const handlePreview = async (pdfId, version = 'original', mode = 'preview') => {
    try {
      setIsPreviewing(true);
      
      if (mode === 'preview') {
        const response = await fetch(`/client/api/pdfs/${pdfId}/preview?version=${version}&mode=${mode}`, {
          credentials: 'include'
        });
        
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        // Open PDF in new tab with explicit PDF viewer
        const pdfWindow = window.open('');
        pdfWindow.document.write(
          `<iframe src="${url}" style="width:100%; height:100%;" frameborder="0"></iframe>`
        );
      } else {
        // Download logic remains the same
        const response = await fetch(`/client/api/pdfs/${pdfId}/preview?version=${version}&mode=${mode}`, {
          credentials: 'include'
        });
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = response.headers.get('Content-Disposition')?.match(/filename="(.+)"$/)?.[1] || 'document.pdf';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.error(`${mode} failed:`, error);
      alert(`Failed to ${mode} PDF. Please try again.`);
    } finally {
      setIsPreviewing(false);
    }
  };

  return (
    <>
      {isUploading && <LoadingOverlay message={`Uploading PDF... ${uploadProgress}%`} />}
      {isPreviewing && <LoadingOverlay message="Loading Preview..." />}
      <div className="p-6">
        <div className="mb-6 flex justify-between items-center">
          <h1 className="text-2xl font-bold">PDF Documents</h1>
          <div className="flex gap-4">
            <input
              type="text"
              placeholder="Search documents..."
              className="input input-bordered w-64"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <>
              <input
                type="file"
                accept=".pdf"
                className="hidden"
                id="pdf-upload"
                onChange={handleFileSelect}
              />
              <label htmlFor="pdf-upload" className="btn btn-primary">
                New Project
              </label>
            </>
          </div>
        </div>
  
        <div className="text-sm text-gray-600 mb-4">
          {getViewModeText()}
        </div>
  
        {/* Upload Modal */}
        <Modal isOpen={isModalOpen} onClose={handleModalClose}>
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-bold">Additional Information Required</h2>
            <button
              onClick={handleModalClose}
              className="text-gray-500 hover:text-gray-700"
              disabled={isUploading}
            >
              ×
            </button>
          </div>
  
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-sm font-medium mb-1">Expected Order Date *</label>
              <input
                type="date"
                required
                className="w-full p-2 border rounded"
                value={formData.expected_order_date}
                onChange={e => setFormData({ ...formData, expected_order_date: e.target.value })}
              />
            </div>
  
            <div>
              <label className="block text-sm font-medium mb-1">Project Location *</label>
              <input
                type="text"
                required
                placeholder="Enter project location"
                className="w-full p-2 border rounded"
                value={formData.project_location}
                onChange={e => setFormData({ ...formData, project_location: e.target.value })}
              />
            </div>
  
            <div>
              <label className="block text-sm font-medium mb-1">Name of General Contractor *</label>
              <input
                type="text"
                required
                placeholder="Enter general contractor name"
                className="w-full p-2 border rounded"
                value={formData.contractor_location}
                onChange={e => setFormData({ ...formData, contractor_location: e.target.value })}
              />
            </div>
  
            <div>
              <label className="block text-sm font-medium mb-1">Quote Needed By *</label>
              <input
                type="date"
                required
                className="w-full p-2 border rounded"
                value={formData.quote_needed_by}
                onChange={e => setFormData({ ...formData, quote_needed_by: e.target.value })}
              />
            </div>
  
            <div>
              <label className="block text-sm font-medium mb-1">Domestic Steel Required? *</label>
              <div className="flex gap-4">
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="domestic_steel"
                    className="mr-2"
                    checked={formData.is_domestic_steel === true}
                    onChange={() => setFormData({ ...formData, is_domestic_steel: true })}
                    required
                  />
                  Yes
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="domestic_steel"
                    className="mr-2"
                    checked={formData.is_domestic_steel === false}
                    onChange={() => setFormData({ ...formData, is_domestic_steel: false })}
                  />
                  No
                </label>
              </div>
            </div>
  
            <div className="flex justify-end gap-3 mt-6">
  <button
    type="button"
    className="px-4 py-2 border rounded hover:bg-gray-100"
    onClick={handleModalClose}
    disabled={isUploading}
  >
    Cancel
  </button>
  <button
  type="submit"
  className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
  disabled={isUploading || !selectedFile}
>
  {isUploading ? 'Uploading...' : 'Upload'}
</button>
</div>

          </form>
        </Modal>
  
        {filteredPDFs.length === 0 ? (
          <div className="bg-gray-50 rounded-lg p-8 text-center">
            <p className="text-gray-600 text-lg">
              No documents found. Upload a PDF to get started.
            </p>
          </div>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100" onClick={() => handleSort('filename')}>
                    Document & Files <SortIndicator columnKey="filename" />
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100" onClick={() => handleSort('organization')}>
                    Organization <SortIndicator columnKey="organization" />
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100" onClick={() => handleSort('upload_date')}>
                    Upload Date <SortIndicator columnKey="upload_date" />
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100" onClick={() => handleSort('questionnaire.project_location')}>
                    Project Location <SortIndicator columnKey="questionnaire.project_location" />
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100" onClick={() => handleSort('questionnaire.contractor_location')}>
                    Contractor Name <SortIndicator columnKey="questionnaire.contractor_location" />
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100" onClick={() => handleSort('status')}>
                    Status <SortIndicator columnKey="status" />
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredPDFs.map(pdf => (
                  <tr key={pdf.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4">
                      <div className="space-y-2">
                        <div className="text-sm font-medium text-gray-900">{pdf.filename}</div>
                        <RelatedFiles pdf={pdf} onPreview={handlePreview} />
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500">{pdf.organization?.name}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500">
                        {new Date(pdf.upload_date).toLocaleDateString()}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500">
                        {pdf.questionnaire?.project_location || '-'}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500">
                        {pdf.questionnaire?.contractor_location || '-'}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`px-2 inline-flex items-center text-xs leading-5 font-semibold rounded-full ${getStatusStyles(pdf.status)}`}>
                        {getStatusIcon(pdf.status)}
                        {pdf.status.charAt(0).toUpperCase() + pdf.status.slice(1)}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="space-y-2">
                        {user.role === 'admin' && (
                          <button
                            onClick={() => handleDelete(pdf.id)}
                            className="flex items-center w-full px-3 py-1 text-sm font-medium text-red-700 bg-red-100 rounded hover:bg-red-200"
                          >
                            <Trash2 className="w-4 h-4 mr-1" />
                            Delete
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
}

export default PDFList;