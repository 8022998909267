import React from 'react';
import { Eye, Upload, Download } from 'lucide-react';

const PDFActions = ({ pdf, onStatusUpdate, onPreview, onProcess }) => {
  const buttonBaseClass = "inline-flex items-center justify-center px-3 py-1 text-sm font-medium rounded";

  const handleDownload = async (type) => {
    try {
      const response = await fetch(`/client/api/pdfs/${pdf.id}/files/${type}`, {
        credentials: 'include'
      });
      
      if (!response.ok) {
        throw new Error(`Failed to download ${type} file`);
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${pdf.filename.split('.')[0]}.${type}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(`Error downloading ${type} file:`, error);
      alert(`Failed to download ${type} file. Please try again.`);
    }
  };

  return (
    <div className="flex flex-wrap gap-2">
      {/* Original PDF buttons - always show */}
      <button
        onClick={() => onPreview(pdf.id, 'original', 'preview')}
        className={`${buttonBaseClass} bg-indigo-100 text-indigo-700 hover:bg-indigo-200`}
      >
        <Eye className="w-4 h-4 mr-1" />
        Preview
      </button>

      <button
        onClick={() => onPreview(pdf.id, 'original', 'download')}
        className={`${buttonBaseClass} bg-green-100 text-green-700 hover:bg-green-200`}
      >
        <Download className="w-4 h-4 mr-1" />
        Download
      </button>

      {/* Processed PDF buttons - only show if processed PDF exists */}
      {pdf.status === 'completed' && pdf.has_processed_pdf && (
        <>
          <button
            onClick={() => onPreview(pdf.id, 'processed', 'preview')}
            className={`${buttonBaseClass} bg-blue-100 text-blue-700 hover:bg-blue-200`}
          >
            <Eye className="w-4 h-4 mr-1" />
            View Processed
          </button>

          <button
            onClick={() => onPreview(pdf.id, 'processed', 'download')}
            className={`${buttonBaseClass} bg-green-100 text-green-700 hover:bg-green-200`}
          >
            <Download className="w-4 h-4 mr-1" />
            Download Processed
          </button>
        </>
      )}

      {/* CSV and XML buttons - only show if those files exist AND status is completed */}
      {pdf.status === 'completed' && pdf.has_csv && (
        <button
          onClick={() => handleDownload('csv')}
          className={`${buttonBaseClass} bg-yellow-100 text-yellow-700 hover:bg-yellow-200`}
        >
          <Download className="w-4 h-4 mr-1" />
          CSV
        </button>
      )}

      {pdf.status === 'completed' && pdf.has_xml && (
        <button
          onClick={() => handleDownload('xml')}
          className={`${buttonBaseClass} bg-purple-100 text-purple-700 hover:bg-purple-200`}
        >
          <Download className="w-4 h-4 mr-1" />
          XML
        </button>
      )}

      {/* Process button - always show */}
      <button
        onClick={() => onProcess(pdf.id)}
        className={`${buttonBaseClass} bg-blue-100 text-blue-700 hover:bg-blue-200`}
      >
        <Upload className="w-4 h-4 mr-1" />
        Process
      </button>
    </div>
  );
};

export default PDFActions;