import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { checkSession, acceptNDA } from '../slices/authSlice';
import { fetchPDFs } from '../slices/pdfSlice';
import LandingPage from './LandingPage';
import LoginForm from './LoginForm';
import PDFList from './PDFList';
import ManageUsers from './ManageUsers';
import SidebarLayout from './SidebarLayout';
import ParentAdminDashboard from './ParentAdminDashboard';
import ParametersManager from './ParametersManager';
import ResetPasswordForm from './ResetPasswordForm';
import SuperadminPDFDashboard from './SuperadminPDFDashboard';
import NDAModal from './NDAModal';
import LocationManager from './LocationManager';

function App() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [showNDA, setShowNDA] = useState(false);
  
  useEffect(() => {
    dispatch(checkSession());
  }, [dispatch]);

  useEffect(() => {
    // Show NDA if user is logged in but hasn't accepted it yet
    if (auth.isAuthenticated && auth.user && !auth.user.nda_accepted_at) {
      setShowNDA(true);
    }
  }, [auth.isAuthenticated, auth.user]);

  useEffect(() => {
    if (auth.isAuthenticated) {
      dispatch(fetchPDFs());
    }
  }, [auth.isAuthenticated, dispatch]);

  const handleNDAAccept = async () => {
    try {
      await dispatch(acceptNDA()).unwrap();
      setShowNDA(false);
    } catch (error) {
      console.error('Failed to accept NDA:', error);
    }
  };

  // If user needs to accept NDA, only show NDA modal
  if (auth.isAuthenticated && auth.user && !auth.user.nda_accepted_at) {
    return <NDAModal isOpen={true} onAccept={handleNDAAccept} />;
  }

  return (
    <Router basename="/client">
      <Routes>
        <Route path="/reset-password/:token" element={<ResetPasswordForm />} />
        {!auth.isAuthenticated ? (
          <>
            <Route path="/" element={<LandingPage />} />
            <Route path="/login" element={<LoginForm />} />
            <Route path="*" element={<Navigate replace to="/" />} />
          </>
        ) : (
          <Route element={<SidebarLayout />}>
            <Route index element={<Navigate to="/pdfs" replace />} />
            <Route path="pdfs" element={<PDFList />} />
            <Route path="parameters" element={<ParametersManager />} />
            <Route
              path="superadmin/pdfs"
              element={
                auth.user?.role === 'parent_admin' ?
                  <SuperadminPDFDashboard /> :
                  <Navigate to="/pdfs" />
              }
            />
            <Route
              path="manage-users"
              element={
                ['admin', 'parent_admin'].includes(auth.user?.role) ? 
                <ManageUsers /> : 
                <Navigate to="/pdfs" />
              }
            />
            <Route 
              path="parent-admin" 
              element={
                auth.user?.role === 'parent_admin' ? 
                <ParentAdminDashboard /> : 
                <Navigate to="/pdfs" />
              }
            />
            <Route path="*" element={<Navigate replace to="/pdfs" />} />
            <Route 
  path="manage-locations" 
  element={
    auth.user?.role === 'parent_admin' ? 
    <LocationManager /> : 
    <Navigate to="/pdfs" />
  }
/>
          </Route>
          
        )}
      </Routes>
    </Router>
  );
}

export default App;