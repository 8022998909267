import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAllPDFs, updatePDFStatus } from '../slices/pdfSlice';
import { 
  AlertCircle, 
  CheckCircle, 
  Clock, 
  AlertTriangle, 
  Loader, 
  ChevronDown, 
  ChevronUp
} from 'lucide-react';
import ProcessPDFModal from './ProcessPDFModal';
import PDFActions from './PDFActions';
import LoadingOverlay from './LoadingOverlay';

const SuperadminPDFDashboard = () => {
  const dispatch = useDispatch();
  const [selectedStatus, setSelectedStatus] = useState('all');
  const [selectedOrg, setSelectedOrg] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedPdfId, setSelectedPdfId] = useState(null);
  const [isProcessModalOpen, setIsProcessModalOpen] = useState(false);
  const { allPDFs, organizations, loading, error } = useSelector(state => ({
    allPDFs: state.pdfs.allDocuments || [],
    organizations: state.auth.organizations,
    loading: state.pdfs.status === 'loading',
    error: state.pdfs.error
  }));
  const [sortConfig, setSortConfig] = useState({
    key: 'upload_date',
    direction: 'desc',
  });
  const [isPreviewing, setIsPreviewing] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    dispatch(fetchAllPDFs());
  }, [dispatch]);

  const filteredPDFs = allPDFs.filter(pdf => {
    const matchesSearch = pdf.filename.toLowerCase().includes(searchQuery.toLowerCase()) ||
      pdf.organization_name?.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesStatus = selectedStatus === 'all' || pdf.status === selectedStatus;
    const matchesOrg = selectedOrg === 'all' || pdf.organization_id === parseInt(selectedOrg);

    return matchesSearch && matchesStatus && matchesOrg;
  });

  const getStatusIcon = (status) => {
    switch (status) {
      case 'pending':
        return <AlertCircle className="w-4 h-4 mr-1" />;
      case 'completed':
        return <CheckCircle className="w-4 h-4 mr-1" />;
      case 'processing':
        return <Loader className="w-4 h-4 mr-1 animate-spin" />;
      case 'failed':
        return <AlertTriangle className="w-4 h-4 mr-1" />;
      default:
        return <Clock className="w-4 h-4 mr-1" />;
    }
  };

  const getStatusStyles = (status) => {
    switch (status) {
      case 'pending':
        return 'bg-purple-100 text-purple-800';
      case 'completed':
        return 'bg-green-100 text-green-800';
      case 'processing':
        return 'bg-blue-100 text-blue-800';
      case 'failed':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-yellow-100 text-yellow-800';
    }
  };

  const handleStatusUpdate = async (pdfId, status, details = {}) => {
    try {
      setIsProcessing(true);
      console.log('Processing PDF...');
      
      await dispatch(updatePDFStatus({ 
        id: pdfId, 
        status,
        ...details
      })).unwrap();
      
      await dispatch(fetchAllPDFs());
      console.log('Processing complete');
    } catch (error) {
      console.error('Failed to update status:', error);
      alert('Failed to process PDF. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  const handleSort = (key) => {
    setSortConfig(current => ({
      key,
      direction: current.key === key && current.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  // const handlePreview = async (pdfId, version = 'original') => {
  //   try {
  //     setIsPreviewing(true);
  //     console.log('Loading preview...');
      
  //     const response = await fetch(`/client/api/pdfs/${pdfId}/preview?version=${version}`, {
  //       credentials: 'include'
  //     });

  //     if (!response.ok) {
  //       throw new Error('Failed to load preview');
  //     }

  //     const blob = await response.blob();
  //     const url = window.URL.createObjectURL(blob);
  //     window.open(url, '_blank', 'noopener,noreferrer');
  //     setTimeout(() => window.URL.revokeObjectURL(url), 100);

  //   } catch (error) {
  //     console.error('Preview failed:', error);
  //     alert('Failed to load preview. Please try again.');
  //   } finally {
  //     setIsPreviewing(false);
  //     console.log('Preview loading complete');
  //   }
  // };

  const handlePreview = async (pdfId, version = 'original', mode = 'preview') => {
    try {
      setIsPreviewing(true);
      console.log(`Loading ${mode}...`);
      
      const response = await fetch(`/client/api/pdfs/${pdfId}/preview?version=${version}&mode=${mode}`, {
        credentials: 'include',
        headers: {
          'Accept': 'application/pdf'
        }
      });
  
      if (!response.ok) {
        throw new Error(`Failed to ${mode} PDF`);
      }
  
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
  
      if (mode === 'download') {
        // Handle download
        const a = document.createElement('a');
        a.href = url;
        // Get filename from Content-Disposition header if available
        const contentDisposition = response.headers.get('Content-Disposition');
        const filenameMatch = contentDisposition && contentDisposition.match(/filename="(.+)"/);
        const filename = filenameMatch ? filenameMatch[1] : 'document.pdf';
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        // Create an embedded viewer
        const embedUrl = url + '#toolbar=0&navpanes=0';
        const viewerWindow = window.open('', '_blank');
        viewerWindow.document.write(`
          <!DOCTYPE html>
          <html>
            <head>
              <title>PDF Preview</title>
              <style>
                body, html {
                  margin: 0;
                  padding: 0;
                  height: 100vh;
                  display: flex;
                  flex-direction: column;
                }
                #pdf-viewer {
                  flex: 1;
                  width: 100%;
                  height: 100%;
                  border: none;
                }
              </style>
            </head>
            <body>
              <embed id="pdf-viewer" src="${embedUrl}" type="application/pdf">
            </body>
          </html>
        `);
        viewerWindow.document.close();
      }
  
      // Cleanup
      setTimeout(() => window.URL.revokeObjectURL(url), 1000);
  
    } catch (error) {
      console.error(`${mode} failed:`, error);
      alert(`Failed to ${mode} PDF. Please try again.`);
    } finally {
      setIsPreviewing(false);
    }
  };

  const SortableHeader = ({ column, label }) => (
    <th
      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
      onClick={() => handleSort(column)}
    >
      <div className="flex items-center">
        {label}
        {sortConfig.key === column && (
          <span className="inline-block ml-1">
            {sortConfig.direction === 'asc' ?
              <ChevronUp className="w-4 h-4" /> :
              <ChevronDown className="w-4 h-4" />
            }
          </span>
        )}
      </div>
    </th>
  );

  const sortedPDFs = React.useMemo(() => {
    return [...filteredPDFs].sort((a, b) => {
      let aValue = a[sortConfig.key];
      let bValue = b[sortConfig.key];

      if (sortConfig.key === 'upload_date') {
        aValue = new Date(aValue).getTime();
        bValue = new Date(bValue).getTime();
      } else if (typeof aValue === 'string') {
        aValue = aValue?.toLowerCase() || '';
        bValue = bValue?.toLowerCase() || '';
      }
      if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
      return 0;
    });
  }, [filteredPDFs, sortConfig]);

  return (
    <div className="p-6">
      {isPreviewing && <LoadingOverlay message="Loading Preview..." />}
      {isProcessing && <LoadingOverlay message="Processing PDF..." />}

      <div className="mb-6">
        <h1 className="text-2xl font-bold mb-4">PDF Management Dashboard</h1>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
          <div>
            <input
              type="text"
              placeholder="Search PDFs..."
              className="w-full p-2 border rounded"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>

          <div>
            <select
              className="w-full p-2 border rounded"
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
            >
              <option value="all">All Statuses</option>
              <option value="pending">Pending</option>
              <option value="in_progress">In Progress</option>
              <option value="processing">Processing</option>
              <option value="completed">Completed</option>
              <option value="failed">Failed</option>
            </select>
          </div>

          <div>
            <select
              className="w-full p-2 border rounded"
              value={selectedOrg}
              onChange={(e) => setSelectedOrg(e.target.value)}
            >
              <option value="all">All Organizations</option>
              {organizations.map(org => (
                <option key={org.id} value={org.id}>{org.name}</option>
              ))}
            </select>
          </div>
        </div>

        {error && (
          <div className="mb-4 p-4 bg-red-50 text-red-700 rounded-lg flex items-center">
            <AlertCircle className="w-5 h-5 mr-2" />
            <span>{error}</span>
          </div>
        )}

        {loading ? (
          <div className="flex justify-center items-center h-32">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
          </div>
        ) : (
          <div className="bg-white rounded-lg shadow overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <SortableHeader column="filename" label="Document" />
                  <SortableHeader column="organization_name" label="Organization" />
                  <SortableHeader column="uploaded_by" label="Uploaded By" />
                  <SortableHeader column="upload_date" label="Upload Date" />
                  <SortableHeader column="status" label="Status" />
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {sortedPDFs.map(pdf => (
                  <tr key={pdf.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4">
                      <div className="text-sm font-medium text-gray-900">{pdf.filename}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500">{pdf.organization_name}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500">{pdf.uploaded_by}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500">
                        {new Date(pdf.upload_date).toLocaleDateString()}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`px-2 inline-flex items-center text-xs leading-5 font-semibold rounded-full ${getStatusStyles(pdf.status)}`}>
                        {getStatusIcon(pdf.status)}
                        {pdf.status.charAt(0).toUpperCase() + pdf.status.slice(1)}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <PDFActions
                        pdf={pdf}
                        onStatusUpdate={handleStatusUpdate}
                        onPreview={handlePreview}
                        onProcess={(pdfId) => {
                          setSelectedPdfId(pdfId);
                          setIsProcessModalOpen(true);
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      <ProcessPDFModal
        isOpen={isProcessModalOpen}
        onClose={() => {
          setIsProcessModalOpen(false);
          setSelectedPdfId(null);
        }}
        pdfId={selectedPdfId}
        onSuccess={() => {
          dispatch(fetchAllPDFs());
        }}
      />
    </div>
  );
};

export default SuperadminPDFDashboard;