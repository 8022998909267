import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { 
  fetchUserParameters,
  selectUserParameters,
  selectParametersStatus
} from '../slices/authSlice';

export const useParameterCheck = () => {
  const dispatch = useDispatch();
  const parameters = useSelector(selectUserParameters);
  const status = useSelector(selectParametersStatus);
  const user = useSelector(state => state.auth.user);
  const [showPrompt, setShowPrompt] = useState(false);

  useEffect(() => {
    const checkParameters = async () => {
      if (status === 'idle') {
        await dispatch(fetchUserParameters());
      }
      
      if (status === 'succeeded' && (!parameters || parameters.length === 0) && user?.role != 'parent_admin') {
        setShowPrompt(true);
      }
    };

    checkParameters();
  }, [dispatch, status, parameters]);

  return { showPrompt, setShowPrompt };
};

export const ParameterPromptModal = ({ isOpen, onClose }) => {
  const navigate = useNavigate();

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
        <h2 className="text-xl font-semibold mb-4">Welcome! Set Your Parameters</h2>
        <p className="text-gray-600 mb-6">
          To get started, you'll need to set up your industry parameters. 
          These will help customize the system to your needs.
        </p>
        <div className="flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="px-4 py-2 text-gray-600 hover:text-gray-800"
          >
            Later
          </button>
          <button
            onClick={() => {
              navigate('/parameters', { 
                state: { 
                  isFirstLogin: true,
                  message: "Welcome! Please set up your parameters to get started." 
                }
              });
              onClose();
            }}
            className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
          >
            Set Parameters Now
          </button>
        </div>
      </div>
    </div>
  );
};