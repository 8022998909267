import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, AlertTitle, AlertDescription } from './Alert';
import { AlertCircle, Save } from 'lucide-react';
import { 
  fetchUserParameters, 
  updateUserParameters,
  selectUserParameters,
  selectParametersStatus,
  selectParametersError
} from '../slices/authSlice';

import { STEEL_GRADE_INFO, PAINT_FINISH_INFO, WELD_SPEC_INFO,LOCATION_INFO } from './steelGradeConstants';
import { Info } from 'lucide-react';
import ParameterDisplay from './ParameterDisplay';

const Tooltip = ({ children }) => (
  <div className="group relative inline-block">
    <Info className="w-4 h-4 text-gray-400 hover:text-gray-600 inline-block ml-1" />
    <div className="invisible group-hover:visible absolute z-10 w-64 p-2 mt-1 text-sm text-white bg-gray-900 rounded-lg opacity-0 group-hover:opacity-100 transition-opacity">
      {children}
    </div>
  </div>
);

const SelectWithTooltip = ({ value, onChange, options, descriptions, label }) => (
  <div>
    <label className="block text-sm font-medium text-gray-700">
      {label}
      {value && descriptions[value] && (
        <Tooltip>{descriptions[value]}</Tooltip>
      )}
    </label>
    <select
      value={value || ''}
      onChange={onChange}
      className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
    >
      <option value="">Select...</option>
      {options.map(option => (
        <option key={option} value={option}>{option}</option>
      ))}
    </select>
  </div>
);

const SteelGradeSection = ({ parameters, onChange }) => {
  const steelGrades = [
    { key: 'default_w_steel_grade', label: 'W Shapes', info: STEEL_GRADE_INFO.W_SHAPES },
    { key: 'default_t_steel_grade', label: 'T Shapes', info: STEEL_GRADE_INFO.T_SHAPES },
    { key: 'default_l_steel_grade', label: 'L Shapes (Angles)', info: STEEL_GRADE_INFO.L_SHAPES },
    { key: 'default_hss_steel_grade', label: 'HSS', info: STEEL_GRADE_INFO.HSS },
    { key: 'default_pipe_steel_grade', label: 'Pipe', info: STEEL_GRADE_INFO.PIPE },
    { key: 'default_plate_steel_grade', label: 'Plate', info: STEEL_GRADE_INFO.PLATE }
  ];

  return (
    <div className="space-y-4">
      <h4 className="text-lg font-medium">Steel Grades</h4>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {steelGrades.map(({ key, label, info }) => (
          <SelectWithTooltip
            key={key}
            label={label}
            value={parameters[key]?.parameter_value}
            onChange={(e) => onChange(key, e.target.value)}
            options={info.options}
            descriptions={info.descriptions}
          />
        ))}
      </div>
    </div>
  );
};


const GeneralSection = ({ parameters, onChange }) => {
  const locations = useSelector(state => state.pdfs.locations.items);
  const locationOptions = locations.map(loc => loc.name);
  const locationDescriptions = locations.reduce((acc, loc) => ({
    ...acc,
    [loc.name]: `${loc.name} Manufacturing Facility`
  }), {});
  return (
    <div className="space-y-4">
      <h4 className="text-lg font-medium">General Settings</h4>
      <div className="grid grid-cols-1 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Default Delivery Address
          </label>
          <textarea
            value={parameters.default_delivery_address?.parameter_value || ''}
            onChange={(e) => onChange('default_delivery_address', e.target.value)}
            className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            rows={3}
          />
        </div>

        <SelectWithTooltip
      label="Infra-Metals Sponsor Location"
      value={parameters.default_location?.parameter_value}
      onChange={(e) => onChange('default_location', e.target.value)}
      options={locationOptions}
      descriptions={locationDescriptions}
    />
        
        <SelectWithTooltip
          label="Default Paint/Finish"
          value={parameters.default_paint_finish?.parameter_value}
          onChange={(e) => onChange('default_paint_finish', e.target.value)}
          options={PAINT_FINISH_INFO.options}
          descriptions={PAINT_FINISH_INFO.descriptions}
        />

        <SelectWithTooltip
          label="Default Weld Specification"
          value={parameters.default_weld_specification?.parameter_value}
          onChange={(e) => onChange('default_weld_specification', e.target.value)}
          options={WELD_SPEC_INFO.options}
          descriptions={WELD_SPEC_INFO.descriptions}
        />

        <div>
          <label className="block text-sm font-medium mb-2">
            Domestic Steel Required
          </label>
          <select
            value={parameters.domestic_steel_required?.parameter_value || 'No'}
            onChange={(e) => onChange('domestic_steel_required', e.target.value)}
            className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          >
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>
      </div>
    </div>
  );
};

const ParametersManager = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const parameters = useSelector(selectUserParameters);
  const status = useSelector(selectParametersStatus);
  const error = useSelector(selectParametersError);
  const [localParameters, setLocalParameters] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const isFirstLogin = location.state?.isFirstLogin;
  const welcomeMessage = location.state?.message;

  useEffect(() => {
    dispatch(fetchUserParameters());
  }, [dispatch]);

  useEffect(() => {
    if (parameters.length > 0) {
      const paramMap = parameters.reduce((acc, param) => ({
        ...acc,
        [param.parameter_name]: param
      }), {});
      setLocalParameters(paramMap);
    }
  }, [parameters]);

  const handleParameterChange = (name, value) => {
    setLocalParameters(prev => ({
      ...prev,
      [name]: { ...prev[name], parameter_value: value }
    }));
  };

  const handleSave = async () => {
    try {
      const parameterUpdates = Object.entries(localParameters).map(([name, param]) => ({
        name,
        value: param.parameter_value
      }));
  
      console.log('Sending parameters:', parameterUpdates); // Add this line
  
      await dispatch(updateUserParameters(parameterUpdates)).unwrap();
      setSuccessMessage('Parameters updated successfully');
      setTimeout(() => setSuccessMessage(''), 3000);
      
      if (isFirstLogin) {
        navigate('/');
      }
    } catch (err) {
      console.error('Failed to update parameters:', err);
    }
  };

  if (status === 'loading') {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto py-6 space-y-6">
      {isFirstLogin && welcomeMessage && (
        <Alert className="mb-6 bg-blue-50 border-blue-200">
          <AlertCircle className="h-4 w-4 text-blue-600" />
          <AlertTitle className="text-blue-800">Welcome</AlertTitle>
          <AlertDescription className="text-blue-700">{welcomeMessage}</AlertDescription>
        </Alert>
      )}

      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold text-gray-900">Parameter Settings</h2>
        <div className="flex space-x-4">
          {isFirstLogin && (
            <button
              onClick={() => navigate('/')}
              className="px-4 py-2 text-gray-600 hover:text-gray-800"
            >
              Skip for Now
            </button>
          )}
          <button
            onClick={handleSave}
            className="flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <Save className="w-4 h-4 mr-2" />
            Save Parameters
          </button>
        </div>
      </div>

      {error && (
        <Alert className="mb-4 bg-red-50">
          <AlertCircle className="h-4 w-4" />
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}

      {successMessage && (
        <Alert className="mb-4 bg-green-50">
          <AlertTitle>Success</AlertTitle>
          <AlertDescription>{successMessage}</AlertDescription>
        </Alert>
      )}

<div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="bg-white rounded-lg shadow-lg p-6 space-y-8">
          <h3 className="text-lg font-medium">Edit Parameters</h3>
          <GeneralSection parameters={localParameters} onChange={handleParameterChange} />
          <SteelGradeSection parameters={localParameters} onChange={handleParameterChange} />
        </div>
        
        <div>
          <ParameterDisplay />
        </div>
      </div>
    </div>
  );
};

export default ParametersManager;