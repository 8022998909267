import React, { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Download } from 'lucide-react';

const LandingPage = () => {
  const videoRef = useRef(null);
  const [activeTab, setActiveTab] = useState('gettingStarted');

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play().catch(error => {
        console.error("Video playback failed:", error);
      });
    }
  }, []);

  const renderTabContent = () => {
    switch(activeTab) {
      case 'gettingStarted':
        return (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-4">Quick Start Guide</h3>
              <ol className="list-decimal list-inside space-y-3">
                <li>Receive login credentials via email</li>
                <li>Set up your account parameters</li>
                <li>Upload your first PDF document</li>
                <li>Access processed results within 24 hours</li>
              </ol>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-4">System Requirements</h3>
              <ul className="list-disc list-inside space-y-3">
                <li>Modern web browser (Chrome, Firefox, Safari)</li>
                <li>PDF viewer for processed projects</li>
                <li>Excel for spreadsheet exports</li>
              </ul>
            </div>
          </div>
        );
      
      case 'features':
        return (
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-4">PDF Processing</h3>
              <ul className="list-disc list-inside space-y-2">
                <li>Automated takeoff processing</li>
                <li>Bluebeam-compatible output</li>
                <li>Excel data export</li>
              </ul>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-4">User Management</h3>
              <ul className="list-disc list-inside space-y-2">
                <li>Role-based access control</li>
                <li>Secure user authentication</li>
                <li>Team collaboration tools</li>
              </ul>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-4">Integration</h3>
              <ul className="list-disc list-inside space-y-2">
                <li>Tekla EPM compatibility</li>
                <li>Export to common formats</li>
                <li>API access available</li>
              </ul>
            </div>
          </div>
        );
      
      case 'resources':
        return (
          <div className="space-y-6">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <div className="flex justify-between items-start mb-6">
                <h3 className="text-xl font-semibold">Documentation & Resources</h3>
                <a 
                  href="/client/static/client-manual.pdf" 
                  download 
                  className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
                >
                  <Download className="w-4 h-4" />
                  Download Manual
                </a>
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="bg-blue-50 p-4 rounded-md">
                  <h4 className="font-medium mb-2">Administrator</h4>
                  <ul className="list-disc list-inside space-y-1">
                    <li>Manage users and administrators</li>
                    <li>Access all company projects</li>
                    <li>Configure company settings</li>
                  </ul>
                </div>
                <div className="bg-green-50 p-4 rounded-md">
                  <h4 className="font-medium mb-2">User</h4>
                  <ul className="list-disc list-inside space-y-1">
                    <li>Upload and manage projects</li>
                    <li>View own project results</li>
                    <li>Set personal parameters</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-4">Support</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <h4 className="font-medium mb-2">Contact Information</h4>
                  <p className="mb-2">Email: support@vectorintelligence.org</p>
                  <p>Response time: Within 24 hours</p>
                </div>
                <div>
                  <h4 className="font-medium mb-2">Additional Resources</h4>
                  <ul className="list-disc list-inside space-y-2">
                    <li>Video tutorials</li>
                    <li>FAQs</li>
                    <li>Best practices guide</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        );
      
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-gray-50">
      <nav className="bg-gray-800 text-white p-4">
        <div className="max-w-7xl mx-auto flex justify-between items-center">
          <div className="flex items-center">
            <img src="/client/static/vilogo.png" alt="Logo" className="h-8 w-8 mr-2" />
            <span className="text-xl font-bold">Vector Intelligence</span>
          </div>
          <Link to="/login" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
            Login
          </Link>
        </div>
      </nav>

      <main className="flex-grow container mx-auto px-4 py-8">
        <div className="max-w-6xl mx-auto">
          <div className="text-center mb-12">
            <h1 className="text-4xl font-bold mb-6">
              Welcome to Vector Intelligence
            </h1>
            <p className="text-xl text-gray-600 mb-8">
              Securely manage and organize your organization's projects
            </p>
            <div className="rounded-lg overflow-hidden shadow-xl mb-12">
              <video
                ref={videoRef}
                className="w-full"
                controls
                autoPlay
                muted
                loop
                playsInline
              >
                <source src="/client/static/VectorVideo.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>

          <div className="space-y-6">
            <div className="flex space-x-4 border-b">
              {['Features', 'Resources'].map((tab) => {
                const tabValue = tab.toLowerCase().replace(' ', '');
                return (
                  <button
                    key={tabValue}
                    className={`px-4 py-2 font-medium ${
                      activeTab === tabValue 
                        ? 'border-b-2 border-blue-500 text-blue-600' 
                        : 'text-gray-600 hover:text-gray-800'
                    }`}
                    onClick={() => setActiveTab(tabValue)}
                  >
                    {tab}
                  </button>
                );
              })}
            </div>

            <div className="mt-6">
              {renderTabContent()}
            </div>
          </div>
        </div>
      </main>

      <footer className="bg-gray-800 text-white py-4">
        <div className="container mx-auto text-center">
          <p>&copy; 2024 Vector Intelligence. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;