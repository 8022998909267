import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchOrganizations,
  fetchOrganizationUsers,
  deleteUser,
  addUser,
  addOrganization,
  deleteOrganization,
  updateOrganization
} from '../slices/authSlice';
import FailureTest from './FailureTest';

const Alert = ({ type, title, message, onClose }) => (
  <div className={`p-4 mb-4 rounded-lg ${type === 'error' ? 'bg-red-50 text-red-800' : 'bg-green-50 text-green-800'}`}>
    <div className="flex justify-between items-start">
      <div>
        <h3 className="font-medium">{title}</h3>
        <p className="mt-1 text-sm">{message}</p>
      </div>
      <button onClick={onClose} className="text-gray-400 hover:text-gray-600">×</button>
    </div>
  </div>
);

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div
        className="bg-white rounded-lg p-6 w-[500px] max-h-[90vh] overflow-y-auto"
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  );
};

const ParentAdminDashboard = () => {
  const dispatch = useDispatch();
  const { organizations, users } = useSelector(state => {
    console.log('Current Redux state:', state.auth);
    return state.auth;
  });
  const [isOrgModalOpen, setIsOrgModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [alertInfo, setAlertInfo] = useState(null);
  const [newOrg, setNewOrg] = useState({ name: '' });
  const [editingOrg, setEditingOrg] = useState(null);
  const [newUser, setNewUser] = useState({
    username: '',
    email: '',
    password: '',
    role: 'user',
    organization_id: ''
  });

  useEffect(() => {
    console.log('Component mounted, fetching organizations...');
    dispatch(fetchOrganizations());
  }, [dispatch]);

  useEffect(() => {
    console.log('Organizations:', organizations);
  }, [organizations]);

  useEffect(() => {
    console.log('Users:', users);
  }, [users]);

  // Log when selected org changes
  useEffect(() => {
    if (selectedOrg) {
      console.log('Selected org changed to:', selectedOrg);
      console.log('Fetching users for org:', selectedOrg);
      dispatch(fetchOrganizationUsers(selectedOrg));
    }
  }, [selectedOrg, dispatch]);

  const handleDeleteOrg = async (orgId) => {
    if (!window.confirm('Are you sure? This will delete all associated users and data.')) return;

    try {
      await dispatch(deleteOrganization(orgId)).unwrap();
      setAlertInfo({
        type: 'success',
        title: 'Success',
        message: 'Organization deleted successfully'
      });
      if (selectedOrg === orgId) {
        setSelectedOrg(null);
      }
    } catch (error) {
      setAlertInfo({
        type: 'error',
        title: 'Error',
        message: error.message || 'Failed to delete organization'
      });
    }
  };

  const handleUpdateOrg = async (e) => {
    e.preventDefault();
    try {
      await dispatch(updateOrganization({
        orgId: editingOrg.id,
        data: { name: editingOrg.name }
      })).unwrap();
      setIsEditModalOpen(false);
      setAlertInfo({
        type: 'success',
        title: 'Success',
        message: 'Organization updated successfully'
      });
    } catch (error) {
      setAlertInfo({
        type: 'error',
        title: 'Error',
        message: error.message || 'Failed to update organization'
      });
    }
  };

  const handleCreateOrg = async (e) => {
    e.preventDefault();
    try {
      await dispatch(addOrganization(newOrg)).unwrap();
      setIsOrgModalOpen(false);
      setNewOrg({ name: '' });
      setAlertInfo({
        type: 'success',
        title: 'Success',
        message: 'Organization created successfully'
      });
    } catch (error) {
      setAlertInfo({
        type: 'error',
        title: 'Error',
        message: error.message || 'Failed to create organization'
      });
    }
  };

  const handleCreateUser = async (e) => {
    e.preventDefault();
    try {
      await dispatch(addUser(newUser)).unwrap();
      setIsModalOpen(false);
      setNewUser({
        username: '',
        email: '',
        password: '',
        role: 'user',
        organization_id: ''
      });
      setAlertInfo({
        type: 'success',
        title: 'Success',
        message: 'User created successfully'
      });
    } catch (error) {
      setAlertInfo({
        type: 'error',
        title: 'Error',
        message: error.message || 'Failed to create user'
      });
    }
  };

  const handleDeleteUser = async (userId) => {
    if (!window.confirm('Are you sure you want to delete this user?')) return;

    try {
      await dispatch(deleteUser(userId)).unwrap();
      setAlertInfo({
        type: 'success',
        title: 'Success',
        message: 'User deleted successfully'
      });
    } catch (error) {
      setAlertInfo({
        type: 'error',
        title: 'Error',
        message: error.message || 'Failed to delete user'
      });
    }
  };

  return (
    <div className="p-6 max-w-7xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Organization Management</h1>
        <button
          onClick={() => setIsOrgModalOpen(true)}
          className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
        >
          Add Organization
        </button>
      </div>

      {alertInfo && (
        <Alert
          {...alertInfo}
          onClose={() => setAlertInfo(null)}
        />
      )}

      <div className="mb-6">
        <div className="bg-white rounded-lg shadow p-6 mb-6">
          <h2 className="text-xl font-semibold mb-4">Organizations</h2>
          <div className="space-y-4">
            {organizations.map(org => (
              <div key={org.id} className="flex justify-between items-center p-3 border rounded hover:bg-gray-50">
                <span className="font-medium">{org.name}</span>
                <div className="space-x-2">
                  <button
                    onClick={() => {
                      setEditingOrg(org);
                      setIsEditModalOpen(true);
                    }}
                    className="text-blue-600 hover:text-blue-800"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDeleteOrg(org.id)}
                    className="text-red-600 hover:text-red-800"
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="mb-6">
          <label className="block text-sm font-medium mb-2">Select Organization to Manage Users</label>
          <select
            className="w-64 p-2 border rounded focus:ring-2 focus:ring-blue-500"
            onChange={(e) => setSelectedOrg(e.target.value)}
            value={selectedOrg || ''}
          >
            <option value="">Select an organization...</option>
            {organizations.map(org => (
              <option key={org.id} value={org.id}>{org.name}</option>
            ))}
          </select>
        </div>
      </div>

      {selectedOrg && (
        <div className="bg-white rounded-lg shadow">
          {console.log('Rendering users table for org:', selectedOrg)}
          {console.log('Available users:', users)}
          <div className="p-4 border-b flex justify-between items-center">
            <h2 className="text-xl font-semibold">Users</h2>
            <button
              onClick={() => setIsModalOpen(true)}
              className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition"
            >
              Add User
            </button>
          </div>

          <div className="overflow-x-auto">
            <table className="w-full">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Username</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Role</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Phone Number</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Organization</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {users.filter(user => user.organization_id === parseInt(selectedOrg)).map(user => (

                  <tr key={user.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap">{user.username}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{user.email}</td>
                    <td className="px-6 py-4 whitespace-nowrap capitalize">{user.role}</td>
                    <td className="px-6 py-4 whitespace-nowrap capitalize">{user.phone_number}</td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {organizations.find(org => org.id === user.organization_id)?.name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <button
                        onClick={() => handleDeleteUser(user.id)}
                        className="text-red-600 hover:text-red-900 font-medium"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {/* Add Organization Modal */}
      <Modal isOpen={isOrgModalOpen} onClose={() => setIsOrgModalOpen(false)}>
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">Add New Organization</h3>
          <button
            onClick={() => setIsOrgModalOpen(false)}
            className="text-gray-400 hover:text-gray-600"
          >
            ×
          </button>
        </div>

        <form onSubmit={handleCreateOrg} className="space-y-4">
          <div>
            <label className="block text-sm font-medium mb-1">Organization Name</label>
            <input
              type="text"
              required
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
              value={newOrg.name}
              onChange={e => setNewOrg({ ...newOrg, name: e.target.value })}
            />
          </div>

          <div className="flex justify-end gap-3">
            <button
              type="button"
              onClick={() => setIsOrgModalOpen(false)}
              className="px-4 py-2 border rounded hover:bg-gray-100"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
            >
              Create Organization
            </button>
          </div>
        </form>
      </Modal>

      {/* Edit Organization Modal */}
      <Modal isOpen={isEditModalOpen} onClose={() => setIsEditModalOpen(false)}>
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">Edit Organization</h3>
          <button
            onClick={() => setIsEditModalOpen(false)}
            className="text-gray-400 hover:text-gray-600"
          >
            ×
          </button>
        </div>

        <form onSubmit={handleUpdateOrg} className="space-y-4">
          <div>
            <label className="block text-sm font-medium mb-1">Organization Name</label>
            <input
              type="text"
              required
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
              value={editingOrg?.name || ''}
              onChange={e => setEditingOrg({ ...editingOrg, name: e.target.value })}
            />
          </div>

          <div className="flex justify-end gap-3">
            <button
              type="button"
              onClick={() => setIsEditModalOpen(false)}
              className="px-4 py-2 border rounded hover:bg-gray-100"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
            >
              Update
            </button>
          </div>
        </form>
      </Modal>

      {/* Add User Modal */}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">Add New User</h3>
          <button
            onClick={() => setIsModalOpen(false)}
            className="text-gray-400 hover:text-gray-600"
          >
            ×
          </button>
        </div>

        <form onSubmit={handleCreateUser} className="space-y-4">
          <div>
            <label className="block text-sm font-medium mb-1">Username</label>
            <input
              type="text"
              required
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
              value={newUser.username}
              onChange={e => setNewUser({ ...newUser, username: e.target.value })}
              placeholder='Enter username'
            />
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">Email</label>
            <input
              type="email"
              required
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
              value={newUser.email}
              onChange={e => setNewUser({ ...newUser, email: e.target.value })}
              placeholder='Enter email'
            />
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">Password</label>
            <input
              type="password"
              required
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
              value={newUser.password}
              onChange={e => setNewUser({ ...newUser, password: e.target.value })}
              placeholder='Enter password'
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Phone Number</label>
            <input
              type="tel"
              required
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
              value={newUser.phone_number || ''}
              onChange={e => setNewUser({ ...newUser, phone_number: e.target.value })}
              placeholder="Enter phone number"
            />
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">Role</label>
            <select
              required
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
              value={newUser.role}
              onChange={e => setNewUser({ ...newUser, role: e.target.value })}
            >
              <option value="user">User</option>
              <option value="admin">Admin</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">Organization</label>
            <select
              required
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
              value={newUser.organization_id}
              onChange={e => setNewUser({ ...newUser, organization_id: e.target.value })}
            >
              <option value="">Select organization...</option>
              {organizations.map(org => (
                <option key={org.id} value={org.id}>{org.name}</option>
              ))}
            </select>
          </div>

          <div className="flex justify-end gap-3 pt-4">
            <button
              type="button"
              onClick={() => setIsModalOpen(false)}
              className="px-4 py-2 border rounded hover:bg-gray-100"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
            >
              Create User
            </button>
          </div>
        </form>
      </Modal>
      <FailureTest/>
    </div>
  );
};

export default ParentAdminDashboard;