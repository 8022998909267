import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { deleteUser, addUser, fetchUsers } from '../slices/authSlice';

const Alert = ({ type, title, message, onClose }) => (
  <div className={`p-4 mb-4 rounded-lg ${type === 'error' ? 'bg-red-50 text-red-800' : 'bg-green-50 text-green-800'}`}>
    <div className="flex justify-between items-start">
      <div>
        <h3 className="font-medium">{title}</h3>
        <p className="mt-1 text-sm">{message}</p>
      </div>
      <button onClick={onClose} className="text-gray-400 hover:text-gray-600">×</button>
    </div>
  </div>
);

function ManageUsers() {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.auth.user);
  const allUsers = useSelector(state => state.auth.users);
  const [alertInfo, setAlertInfo] = useState(null);

  const users = allUsers.filter(user => {
    console.log('Filtering user:', user);
    console.log('Current user org:', currentUser?.organization?.id);
    return user.organization_id === currentUser?.organization?.id;
  });

  const [newUser, setNewUser] = useState({
    username: '',
    email: '',
    password: '',
    role: 'user',
    organization_id: currentUser.organization_id
  });

  useEffect(() => {
    console.log('Fetching users...');
    dispatch(fetchUsers()).then(response => {
      console.log('Fetched users response:', response);
    });
  }, [dispatch]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await dispatch(addUser(newUser)).unwrap();
      setNewUser({
        username: '',
        email: '',
        password: '',
        role: 'user',
        organization_id: currentUser.organization_id
      });
      setAlertInfo({
        type: 'success',
        title: 'Success',
        message: 'User created successfully'
      });
    } catch (error) {
      setAlertInfo({
        type: 'error',
        title: 'Error',
        message: error.message || 'Failed to create user'
      });
    }
  };

  const handleDelete = async (userId) => {
    if (!window.confirm('Are you sure you want to delete this user?')) return;

    try {
      await dispatch(deleteUser(userId)).unwrap();
      setAlertInfo({
        type: 'success',
        title: 'Success',
        message: 'User deleted successfully'
      });
      dispatch(fetchUsers());
    } catch (error) {
      setAlertInfo({
        type: 'error',
        title: 'Error',
        message: error.message || 'Failed to delete user'
      });
    }
  };

  console.log('Current user:', currentUser);
  console.log('All users:', allUsers);
  console.log('Filtered users:', users);

  return (
    <div className="p-6">
      <h2 className="text-2xl font-bold mb-6">Manage Users</h2>

      {alertInfo && (
        <Alert
          {...alertInfo}
          onClose={() => setAlertInfo(null)}
        />
      )}

      <div className="bg-white rounded-lg shadow p-6 mb-8">
        <h3 className="text-lg font-semibold mb-4">Add New User</h3>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium mb-1">Username</label>
            <input
              type="text"
              required
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
              value={newUser.username}
              onChange={e => setNewUser({ ...newUser, username: e.target.value })}
            />
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">Email</label>
            <input
              type="email"
              required
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
              value={newUser.email}
              onChange={e => setNewUser({ ...newUser, email: e.target.value })}
            />
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">Password</label>
            <input
              type="password"
              required
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
              value={newUser.password}
              onChange={e => setNewUser({ ...newUser, password: e.target.value })}
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Phone Number</label>
            <input
              type="tel"
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
              value={newUser.phone_number || ''}
              onChange={e => setNewUser({ ...newUser, phone_number: e.target.value })}
            />
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">Role</label>
            <select
              required
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
              value={newUser.role}
              onChange={e => setNewUser({ ...newUser, role: e.target.value })}
            >
              <option value="user">User</option>
              <option value="admin">Admin</option>
            </select>
          </div>

          <button
            type="submit"
            className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
          >
            Add User
          </button>
        </form>
      </div>

      <div className="bg-white rounded-lg shadow">
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Username</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Role</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {users.map(user => (
                <tr key={user.id}>
                  <td className="px-6 py-4 whitespace-nowrap">{user.username}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{user.email}</td>
                  <td className="px-6 py-4 whitespace-nowrap capitalize">{user.role}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {user.id !== currentUser.id && (
                      <button
                        onClick={() => handleDelete(user.id)}
                        className="text-red-600 hover:text-red-900 font-medium"
                      >
                        Delete
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ManageUsers;